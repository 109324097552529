import md5 from '@/md5'
import router from '@/router'
import {setCookie, getCookie} from '@/cookies'
import api from '@/api'

const salt = 'beresnev';
const cookieHashName = 'device_id';

const cookiesRefreshAfterDays = 1;

export default {
	namespaced: true,
	state: {
		device_id: "",
		isLoggedIn: false,
		token: ''
	},
	mutations: {
		setDevice_id(state, device_id){
			state.device_id = device_id;
		},
		_setIsLoggedIn(state, data){
			state.isLoggedIn = data
		},
		setIsLoggedIn(state, data){
			state.isLoggedIn = data
			setCookie('isLoggedIn', JSON.stringify(data))
		},
		_setToken(state, token){
			state.token = token
		},
		setToken(state, {token, expires}){
			state.token = token
			saveToken(token, expires)
		}
	},
	actions: {
		init({commit}){
			commit('setDevice_id', getCookie(cookieHashName))

			const isLogined = JSON.parse(getCookie('isLoggedIn'))
			commit('_setIsLoggedIn', isLogined)
			commit('_setToken', getCookie('token'))

			const tokenEndTime = new Date(+getCookie('tokenDate'));
			tokenEndTime.setDate(tokenEndTime.getDate() + cookiesRefreshAfterDays);
			if(tokenEndTime < (new Date) && isLogined){
				setTimeout(() => {
					api.refreshToken()
					.then(res => {
						commit('setToken', { token: res.token, expires: res.options.expires })
					})
				}, 1000);
			}
		},
		generateDevice_id({commit}, {login, password}){
			const hash = md5(
				login + salt +
				password + salt + 
				navigator.userAgent + location.hostname
			)
			commit('setDevice_id', hash)
			setCookie(cookieHashName, hash)
		},
		logout({commit}){
			commit('setIsLoggedIn', false)
			saveToken('logged out', -1);
			router.push({name: 'Login'})
		}
	},
	getters: {
		device_id(state){
			return state.device_id
		},
		isLoggedIn(state){
			return state.isLoggedIn
		},
		token(state){
			return state.token
		}
	}
}

function saveToken(data, expires){
	setCookie('token', data, expires);
	setCookie('tokenDate', new Date().getTime(), expires);
}