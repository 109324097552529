import { createStore } from 'vuex'
import platforms from "./platforms"
import auth from "./auth"
import projects from "./projects"
import dropdown from "./dropdown"

export default createStore({
	state: {
		modal: false
	},
	mutations: {
		setModal(state, val){
			state.modal = val
		}
	},
	getters: {
		modalState(state){
			return state.modal
		}
	},
  modules: {
		platforms,
		auth,
		projects,
		dropdown
  }
})
