export default {
	namespaced: true,
	state: {
		options: {
			visible: false,
			x: 0,
			y: 0
		},
		data: {},
		type: 'qr',
		item: null
	},
	mutations: {
		setVisible(state, data){
			state.options.visible = data
		},
		setPos(state, {x,y}){
			state.options.x = x
			state.options.y = y
		},
		setType(state, data){
			state.type = data;
		},
		setData(state, data){
			state.data = data;
		},
		setItem(state, item){
			state.item = item
		}
	},
	actions: {
		open({commit, dispatch}, {target, data}){
			commit('setType', data.type)
			commit('setData', data.file)

			setTimeout(() => {
				commit('setVisible', true);
			}, 0);
			commit('setItem', target)
			dispatch('recalculate')
		},
		recalculate({state, commit}){
			const item = state.item.closest('.file__button');
			const itemData = item.getBoundingClientRect();

			const game = document.querySelector('.game');
			const parentData = game.getBoundingClientRect();

			let y = itemData.top - parentData.top + game.scrollTop;
			let x = itemData.left - parentData.left;

			y += item.clientHeight / 2;
			x += item.clientWidth / 2;

			commit('setPos', {x, y})
		}
	},
	getters: {
		options(state){
			return state.options;
		},
		data(state){
			return state.data
		},
		type(state){
			return state.type
		}
	}
}