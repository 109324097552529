export default {
	namespaced: true,
	state: {
		selectedPlatform: {},
		platformsList: [
			{
				icon: 'apple',
				title: 'Apple iOS',
				fullTitle: 'Apple Store',
				os: 'ios'
			},
			{
				icon: 'android',
				title: 'Android',
				fullTitle: 'Google Android',
				os: 'android'
			},
			{
				icon: 'amazon',
				title: 'Amazon',
				fullTitle: 'Amazon Store',
				os: 'android'
			},
			{
				icon: 'all',
				title: 'Все',
				fullTitle: '',
				os: 'any'
			}
		],
	},
	mutations: {
		setSelectedPlatform(state, data){
			state.selectedPlatform = data;
		}
	},
	actions: {
		selectPlatform({getters, commit}, platform){
			const platforms = getters['selectedPlatform']
			if(!platforms.includes(platform)){
				platforms.push(platform)
				commit('setSelectedPlatform', platforms)
			}
		},
		unselectPlatform({getters, commit}, platform){
			const platforms = getters['selectedPlatform']
			const index = platforms.indexOf(platform)
			if(~index){
				platforms.splice(index, 1)
				commit('setSelectedPlatform', platforms)
			}
		},
		toggleSelectPlatform({state, dispatch}, platform){
			if(!state.selectedPlatform.includes(platform)){
				dispatch('unselectPlatform', platform);
			} else {
				dispatch('selectPlatform', platform);
			}
		}
	},
	getters: {
		current(state){
			state
			const userDeviceArray = [
				{device: 'Android', platform: /Android/},
				{device: 'Apple iOS', platform: /iPhone/},
				{device: 'Apple iOS', platform: /iPad/}
			];

			const platform = navigator.userAgent;
			for (const i in userDeviceArray) {
				if (userDeviceArray[i].platform.test(platform)) {
					return userDeviceArray[i].device;
				}
			}

			if(navigator.maxTouchPoints &&
				navigator.maxTouchPoints > 2 &&
				(/MacIntel/.test(navigator.platform) || /macintosh/i.test(navigator.platform))
			){
				return 'Apple iOS'
			}

			return 'Все';
		},
		selected(state, getters){
			if(!Object.keys(state.selectedPlatform).length)
				return state.platformsList.find(obj => obj.title == getters.current)
			return state.selectedPlatform;
		},
		list(state){
			return state.platformsList;
		}
	}
}