import axios from 'axios'

import {getCookie} from '@/cookies'

const ax = new axios.create({
	baseURL: 'https://back.beresnev.games',
	withCredentials: true,
	mode: 'no-cors',
})

import store from './store'

ax.interceptors.response.use(r=>r, error => {
	if(error.response.status === 401){
		store.dispatch('auth/logout');
		store
	}
	console.error(error.response);
})

const api = {
	device_id: getCookie('device_id'),

	token(){
		return {
			device_id: this.device_id,
			token: store.getters['auth/token']
		}
	},

	login(login, password){
		store.dispatch('auth/generateDevice_id', {login, password});
		this.device_id = getCookie('device_id');
		return new Promise((resolve, reject) => {
			ax.post('/login', {
				login,
				password,
				...this.token()
			},
			{
				withCredentials: true
			})
			.then(res => {
				store.commit('auth/setToken', { token: res.data.token, expires: res.data.options.expires })
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	registration(registerData){
		store.dispatch('auth/generateDevice_id', {login: registerData.login, password: registerData.password});
		this.device_id = getCookie('device_id');
		return new Promise((resolve, reject) => {
			ax.post('/registration', {
				...registerData,
				...this.token()
			})
			.then(res => {
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	getProjects(isActiveOnly = false){
		return new Promise((resolve, reject) => {
			const data = {
				...this.token()
			}
			if(isActiveOnly){
				data.branches_type = 'active'
			}
			ax.post('/getProjects', data)
			.then(res => {
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	getBranches(project_id, branches_type, limit = 3){
		return new Promise((resolve, reject) => {
			const data = {
				...this.token(),
				project_id,
				branches_type,
				limit
			}
			ax.post('/getBranches', data)
			.then(res => {
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	downloadFile({file_id, type}){
		return new Promise((resolve, reject) => {
			const config = {
				file_id,
				...this.token()
			}
			if(type){
				config.type = type
			}
			ax.post('/downloadFile', config)
			.then(res => {
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	download(file_id, isIpa){
		const data = {
			file_id
		}
		if(isIpa)
			data.type = "direct"
		return new Promise((resolve, reject) => {
			this.downloadFile(data)
			.then(resolve)
			.catch(reject)
		})
	},

	refreshToken(){
		return new Promise((resolve, reject) => {
			ax.post('/refreshToken', {
				...this.token()
			})
			.then(res => {
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	settings(data){
		return new Promise((resolve, reject) => {
			ax.post('/settings', {
				...data,
				...this.token()
			})
			.then(res => {
				if(res.data !== "OK"){
					throw res
				}
				resolve(res.data);
			})
			.catch(reject)
		})
	},

	manageBranch(branch_id, pinned){
		return this.settings({branch_id, pinned})
	},

	manageProject(project_id, pinned){
		return this.settings({project_id, pinned})
	},

	feedback(message){
		return new Promise((resolve, reject) => {
			ax.post('/feedback', {
				...this.token(),
				message
			})
			.then(res => {
				if(res.data !== "OK"){
					throw res
				}
				resolve(res.data);
			})
			.catch(reject)
		})
	},
}

export default api
