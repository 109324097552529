<template>
  <router-view/>
	<div v-if="modalVisible" class="modal__container" @click="() => $store.commit('setModal', false)">
		<div class="modal__wrapper" @click.stop="() => 1">
			<div class="modal__close" @click="() => $store.commit('setModal', false)">
				<svg-icon icon-class="cross"></svg-icon>
			</div>
			<template v-if="state == 'pending'">
				<div class="modal__title">Обратная связь</div>
				<div class="modal__textarea">
					<textarea name="" id="" cols="30" rows="10" v-model="message"></textarea>
				</div>
				<div class="modal__footer">
					<button @click="send">Отправить</button>
				</div>
			</template>
			<template v-else-if="state == 'success'">
				<div class="modal__title">Сообщение отправлено...</div>
			</template>
		</div>
	</div>
</template>

<script>
import api from "@/api"

export default {
	name: 'app',
	data: () => ({
		message: '',
		state: 'pending'
	}),
	computed: {
		loggedIn(){
			return this.$store.getters['auth/isLoggedIn'];
		},
		modalVisible(){
			return this.$store.getters['modalState']
		}
	},
	methods: {
		routerController({to, next}){
			if(to.matched.some(el => el.meta.requiredUnloggedIn && this.loggedIn)){
				next({name: 'Games hub'})
			}
			if(to.matched.some(el => el.meta.requiredLoggedIn && !this.loggedIn)){
				next({name: 'Login'})
			}
			next()
		},
		send(){
			if(this.message.length < 4)
				return
			api.feedback(this.message)
			.then(() => {
				this.state = 'success'
				this.message = ''

				setTimeout(() => {
					this.state = 'pending'
					this.$store.commit('setModal', false)
				}, 2000);
			})
		}
	},
	created() {
		this.$router.isReady()
		.then(() => {
			if(this.$route.matched.some(el => el.meta.requiredUnloggedIn && this.loggedIn)){
				this.$router.push({name: 'Games hub'})
			}
			if(this.$route.matched.some(el => el.meta.requiredLoggedIn && !this.loggedIn)){
				this.$router.push({name: 'Login'})
			}
		})

		this.$store.dispatch('auth/init')
		this.$router.beforeEach((to, from, next) => {
			this.routerController({to, from, next});
		})

		this.$router.afterEach((to) => {
			document.title = `${to.meta.title ? to.meta.title : to.name} | Beresnev`
		})

		if(this.loggedIn){
			this.$store.dispatch('projects/fetch')
		}
	}
}
</script>

<style lang="sass">
@import "./styles.scss"	

.modal
	&__container
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		background-color: rgba($black, 0.25)
		display: flex
		justify-content: center
		align-items: center
		z-index: 1000

	&__wrapper
		background: $white
		padding: 15px 25px
		border-radius: 9px
		display: flex
		width: calc(100% - 20px)
		flex-direction: column
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06), 0 0 0 1px $buttons-border
		position: relative

	&__close
		position: absolute
		top: 20px
		right: 20px
		padding: 5px
		cursor: pointer

	&__title
		font-size: 32px
		padding: 15px 0px 20px
		color: $bullet-active

	&__textarea
		flex-grow: 1
		display: flex
		textarea
			width: 100%
			resize: none
			font-family: "Mazzard"
			padding: 10px 13px
			border: 1px solid $gray-dark-2
			outline: none
			border-radius: 5px

			&:focus
				border: 1px solid $gray-dark-3

	&__footer
		padding: 15px 0 5px
		display: flex
		justify-content: end

		button
			font-family: "Mazzard"
			border-radius: 8px
			border: none
			cursor: pointer
			padding: 10px 15px
			background: transparent
			border: 1px solid $bullet-active
			color: $bullet-active
			transition: background-color .2s ease, color .2s ease
			&:hover
				color: $white
				background-color: $bullet-active

@media screen and (min-width: 500px)
	.modal
		&__wrapper
			min-width: 500px
			width: initial

</style>
