// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Root',
		redirect: '/login'
    // component: Home
  },
  {
    path: '/login',
    name: 'Login',
		meta: {
			requiredUnloggedIn: true
		},
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
		meta: {
			// requiredUnloggedIn: true
		},
    component: () => import('../views/register.vue')
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import('../views/tutorial.vue')
  },
  {
    path: '/g',
    name: 'Games hub',
		meta: {
			requiredLoggedIn: true
		},
    component: () => import('../views/gameHub.vue')
  },
  {
    path: '/g/:gameId',
    name: 'Game',
		meta: {
			requiredLoggedIn: true
		},
    component: () => import('../views/game.vue')
  },
	{
		path: '/components',
		name: 'Components',
		component: () => import('../views/components.vue')
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: '404' }
	},

]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router
