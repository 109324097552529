import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { VueWindowSizePlugin } from 'vue-window-size/option-api';

import SvgIcon from "@/components/basic/svgIcon.vue";

const app = createApp(App);
app.use(store).use(router);
app.use(VueWindowSizePlugin);

app.component('svg-icon', SvgIcon)
 
const req = require.context('./assets/svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req)

app.mount('#app');