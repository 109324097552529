import api from "@/api"

export default {
	namespaced: true,
	state: {
		projects: tryObjFromLocalStorage('projects', '[]'),
		loading: false,
		filesUrls: {},
		archived: tryObjFromLocalStorage('archived', '{}'),
		archiveLoading: false,
		sorted: []
	},
	mutations: {
		setProjects(state, data){
			state.projects = data
		},
		setSorted(state, data){
			state.sorted = data.sort((a, b) => b.pinned - a.pinned)
		},
		setLoading(state, data){
			state.loading = data
		},
		setArchiveLoading(state, data){
			state.archiveLoading = data
		},
		setLink(state, {id, url}){
			state.filesUrls[id] = url
		},
		setArchived(state, {id, val}){
			if(!state.archived[id])
				state.archived[id] = {}
			state.archived[id] = val;
		}
	},
	actions: {
		fetch({commit}){
			commit('setLoading', true);
			return new Promise((resolve, reject) => {
				api.getProjects(true)
				.then(res => {
					commit('setLoading', false);
					commit('setProjects', res.projects);
					commit('setSorted', res.projects);
					localStorage.setItem('projects', JSON.stringify(res.projects))
					resolve(res)
				})
				.catch(reject)
			})
		},
		fetchArchived({state, commit}, {project_id, unlimited = false}){
			commit('setArchiveLoading', true);
			return new Promise((resolve, reject) => {
				api.getBranches(project_id, 'archived', unlimited ? 0 : undefined)
				.then(res => {
					commit('setArchiveLoading', false);
					commit('setArchived', {id: project_id, val: res});
					localStorage.setItem('archived', JSON.stringify(state.archived))
					resolve(res)
				})
				.catch(reject)
			})
		},
	},
	getters: {
		all(state){
			return state.projects
		},
		sorted(state){
			return state.sorted
		},
		isLoading(state){
			return state.loading
		},
		isArchiveLoading(state){
			return state.archiveLoading
		},
		urls(state){
			return state.filesUrls
		},
		archived(state){
			return state.archived
		}
	}
}

function tryObjFromLocalStorage(localStorageName, deflt = '[]') {
	const val = localStorage.getItem(localStorageName) ?? deflt;
	let result;
	try {
		result = JSON.parse(val);
	} catch (error) {
		return JSON.parse(deflt)
	}
	return result;
}