
export function setCookie(name, value, expiress = undefined, days = undefined) {
	let expires = "";
	if(expiress) {
		expires = "; expires=" + (new Date(expiress)).toUTCString();
	}
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days*24*60*60*1000));
		expires = "; expires=" + date.toUTCString();
	}
	const cookie = name + "=" + (value || "")  + expires + "; path=/";
	document.cookie = cookie
}

export function getCookie(name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		let c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}